<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Subjects and Actions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="primary" text outlined class="mr-5" right @click="getRoutes">
          <v-icon>mdi-refresh-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-btn
          class="mr-1"
          to="/subjects_and_actions/create"
          outlined
          color="toolbarIcon"
          text
        >
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>

        <v-autocomplete
          dense
          outlined
          v-model="Type"
          :items="actionType"
          item-text="text"
          item-value="value"
          label="Type"
        ></v-autocomplete>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row dense>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            label="search"
            style="width: 550px"
          ></v-text-field>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="ResData"
              :search="search"
              :loading="loader"
            >
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.Active="props">
                <span class="green--text" dark v-if="props.item.Active == 1">Active</span>
                <span class="red--text" dark v-if="props.item.Active == 0"
                  >In-active</span
                >
              </template>
              <template v-slot:item.id="{ item }">
                <v-btn
                  color="primary"
                  text
                  :to="`/subjects_and_actions/edit/${item.id}?Type=${Type}`"
                  ><v-icon color="black">mdi-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      loader: false,
      headers: [
        { text: "No.", value: "index" },
        { text: "Name", value: "Name" },
        { text: "Status", value: "Active" },
        { text: "Created On", value: "created_at" },
        { text: "Actions", value: "id", sortable: false },
      ],
      actionType: [
        { text: "Actions", value: 2 },
        { text: "Subjects", value: 1 },
      ],
      Type: 1,
      ResData: [],
    };
  },
  methods: {
    getRoutes() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/subjects_and_actions?Type=${this.Type}`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.ResData = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
  },
  created() {
    this.getRoutes();
  },
};
</script>
